import { Datalogger } from 'src/components/leistungen/Datalogger'
import { DPFFilterueberwachungMitSensorik } from 'src/components/leistungen/DPFFilterueberwachungMitSensorik'
import { DpfReinigung } from 'src/components/leistungen/DpfReinigung'
import { Ersatzteile } from 'src/components/leistungen/Ersatzteile'
import { MonitoringSysteme } from 'src/components/leistungen/MonitoringSysteme'
import { RegenerationsSteuerungen } from 'src/components/leistungen/RegenerationsSteuerungen'
import { Section } from 'src/components/leistungen/Section'
import { Link } from 'src/components/link'
import React from 'react'
import { Wrapper } from 'src/components/layout/wrapper'


export default () => (
  <Wrapper subtitle="Leistungsübersicht">
    <h1>Leistungsübersicht</h1>

    <div className="flex flex-col">
      <Link className="buttonLink" to="#monitoring-systeme">&rarr; Monitoring-Systeme</Link>
      <Link className="buttonLink" to="#filterueberwachung">&rarr; DPF-Filterüberwachung mit Sensorik</Link>
      <Link className="buttonLink" to="#regenerations-steuerungen">&rarr; Regenerations-Steuerungen</Link>
      <Link className="buttonLink" to="#datalogger">&rarr; Datalogger</Link>
      <Link className="buttonLink" to="#ersatzteile-pio-can">&rarr; Ersatzteile PIO-CAN</Link>
      <Link className="buttonLink" to="#ersatzteile-pio2000">&rarr; Ersatzteile PIO2000</Link>
      <Link className="buttonLink" to="#diesel-partikelfilter-russfilter">&rarr; Diesel Partikelfilter-Russfilter</Link>
      <Link className="buttonLink" to="#der-particulator">&rarr; Der PARTICULATOR</Link>
      <Link className="buttonLink" to="#dpf-ersatzteile-jm-systeme">&rarr; DPF-Ersatzteile für JM-Systeme</Link>
      <Link className="buttonLink" to="#dpf-reinigung">&rarr; DPF-Reinigung</Link>
    </div>

    <br/>

    <Section id="monitoring-systeme"><MonitoringSysteme/></Section>
    <Section id="filterueberwachung"><DPFFilterueberwachungMitSensorik/></Section>
    <Section id="regenerations-steuerungen"><RegenerationsSteuerungen/></Section>
    <Section id="datalogger"><Datalogger/></Section>
    <Section id="ersatzteile"><Ersatzteile/></Section>
    <Section id="dpf-reinigung"><DpfReinigung/></Section>

  </Wrapper>
)
