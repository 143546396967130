import React from 'react'
import imgB12 from 'src/resources/img/RST-Anschlussschlauch.jpg'
import imgB10 from 'src/resources/img/RST-DSL-0,8m-2.jpg'
import imgB3 from 'src/resources/img/RST-PIO-CAN-DS.jpg'
import imgB7 from 'src/resources/img/RST-PIO-CAN-Flansch.jpg'
import imgB11 from 'src/resources/img/RST-Steckerset-2p.jpg'
import imgB8 from 'src/resources/img/RST-Verschlusskappe-USB.jpg'
import imgB13 from 'src/resources/img/RST-USB-Kabel.jpg'
import imgB14 from 'src/resources/img/RST-Temperaturfuehler-mit-MU101.jpg'
import imgB15 from 'src/resources/img/RST-Schiebemuffe-12-6mm.jpg'

export const ErsatzteilePioCan = () => {
  return (
    <>
      <h2>Ersatzteile PIO-CAN</h2>

      <div className="flex flex-col md:flex-row">
        <div className="flex-none ersatzteile-list">
          <ul className="list">
            <li>Drucksensor</li>
            <li>DS-Anschlusskabel</li>
            <li>Temperaturfühler PIO-CAN-USB</li>
            <li>Messumformer 4-20mA</li>
            <li>Schiebemuffe</li>
            <li>Temperaturfühler PIO-CAN-BUN</li>
            <li>Messumformer 1-10V</li>
            <li>Schiebemuffe ¼“</li>
            <li>USB Verschlussstopfen</li>
            <li>Steckersets</li>
          </ul>
          <br/>
        </div>
        <div className="flex flex-col xl:pl-20">
          <div className="flex flex-row md:max-w-2xl">
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgB7} alt="RST PIO-CAN-Flansch"/></div>
            </div>
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgB8} alt="RST Verschlusskappe-USB"/></div>
            </div>
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgB11} alt="RST Steckerset-2p"/></div>
            </div>
          </div>
          <div className="flex flex-row md:max-w-2xl">
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgB3} alt="RST PIO-CAN-DS"/></div>
            </div>
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgB10} alt="RST DSL-0,8m-2"/></div>
            </div>
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgB12} alt="RST Anschlussschlauch"/></div>
            </div>
          </div>
          <div className="flex flex-row md:max-w-2xl">
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgB13} alt="RST PIO-CAN-DS"/></div>
            </div>
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgB14} alt="RST DSL-0,8m-2"/></div>
            </div>
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgB15} alt="RST Anschlussschlauch"/></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
