import React from 'react'

interface Props {
  id: string,
  children: unknown,
}

export const Section: React.FC<Props> = (props) => {
  return (
    <>
      <div id={props.id}>
        {props.children}
      </div>

      <br/><br/>
    </>
  )
}
