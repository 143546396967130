import React from 'react'

export const DieselPartikelfilterRussfilter = () => {
  return (
    <>
      <h2>Diesel-Partikelfilter / Russfilter / Rußfilter / Partikelfilter</h2>

      <p>
        Wir liefern Dieselpartikelfilter für mobile und stationäre Dieselmotoren mit Partikel-Abscheideraten &gt;99%<br/>
        Zur Nachrüstung oder für Neuanlagen mit Motorleistungen von 1kW bis &gt;1000kW.
      </p>
    </>
  )
}
