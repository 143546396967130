import React from 'react'
import imgReinigung from 'src/resources/img/dpf_reinigung.jpg'
import imgR1 from 'src/resources/img/r1.jpg'
import imgR2 from 'src/resources/img/r2.jpg'
import imgR3 from 'src/resources/img/r3.jpg'
import imgR4 from 'src/resources/img/r4.jpg'

export const DpfReinigung = () => {
  return (
    <>
      <h1>DPF-Reinigung</h1>

      <p>Russfilter gibt es in verschiedenen Ausführungen, mit und ohne katalytische Beschichtungen und Materialien. Nach Anlieferung und Durchführung einer ersten Prüfung durchläuft der DPF einen schonenden Reingungsprozess. Nach Beendigung der Reinigung kann der Filter mit nahezu neuwertiger Standzeit wieder versendet und vom Kunden wieder eingebaut werden.
        <br/>
        Die häufigsten Ausfälle der Partikelfilter sind auf Ablagerungen von nicht brennbaren Ascherückständen zurückzuführen. Diese können jedoch durch geeignete Verfahren entfernt und der Rußfilter wieder eingesetzt werden.
        <br/>
        Diese kostengünstige Variante erspart den Neukauf eines teuren Ersatzfilters oder Nachrüstfilters.
      </p>

      <br/>

      <div className="flex flex-col md:flex-row">
        <div className="flex-1 mb-10 md:mb-0">
          <div className="flex mb-3">
            <img className="mr-2" src={imgR1} alt=""/>
            <img src={imgR2} alt=""/>
          </div>
          <div>Filter mit deutlichen Rußablagerungen</div>
        </div>
        <div className="flex-1">
          <div className="flex mb-3">
            <img className="mr-2" src={imgR3} alt=""/>
            <img src={imgR4} alt=""/>
          </div>
          <div>Nach dem Entfernen der Rückstände, wie Russ und Ölasche aus dem Filtermaterial können die Filter wieder eingesetzt werden.</div>
        </div>
      </div>

      <br/>

      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex-1 pb-3 md:pr-3 md:pb-0">
          Wir reinigen die Filter mit unserer selbst entwickelten Reinigungsanlage. Die Filter werden hoch erhitzt und dann ausgeblasen um auch die letzten Partikelreste zu entfernen.
        </div>
        <div className="flex-1 rounded-xl truncate">
          <img className="w-full" src={imgReinigung} alt=""/>
        </div>
      </div>
    </>
  )
}
