import React from 'react'
import imgB2 from 'src/resources/img/RST-CD.jpg'
import imgB5 from 'src/resources/img/RST-PIO-CAN-1.jpg'

export const Datalogger = () => {
  return (
    <>
      <h1>Datalogger</h1>

      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex-1 pb-3 md:pr-3 md:pb-0">
          <b>PIO-CAN-DL</b><br/>
          Datenlogger für bis zu acht analoge Eingänge.<br/>
          Aufzeichnungsintervall frei einstellbar, kundenspezifische Konfiguration auf Anfrage.
        </div>
        <div className="flex flex-1 justify-center">
          <div><img className="md:max-w-sm" src={imgB5} alt="RST PIO-CAN 1"/></div>
        </div>
      </div>

      <br/>

      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex-1 pb-3 md:pr-3 md:pb-0">
          <b>PIO-CAN-Software V4.0</b><br/>
          Software zur Konfiguration und Analyse des Dataloggers und Protokollspeichers.
        </div>
        <div className="flex flex-1 justify-center">
          <div><img className="md:max-w-sm" src={imgB2} alt="RST CD"/></div>
        </div>
      </div>
    </>
  )
}
