import React from 'react'
import imgB5 from 'src/resources/img/RST-PIO-CAN-1.jpg'
import imgB6 from 'src/resources/img/RST-PIO-CAN-BUN.jpg'

export const RegenerationsSteuerungen = () => {
  return (
    <>
      <h1>Regenerations-Steuerungen</h1>
      <p>Mit dem Steuerungsmodul PIO-CAN-USB können zusätzlich zur kontinuierlichen Filterüberwachung auch Steuerung und Regelung von DPF-Regenerationen ausgeführt werden.</p>

      <br/>

      <div className="flex justify-center">
        <div><img src={imgB6} alt="RST PIO-CAN-BUN"/></div>
      </div>

      <br/>

      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex-1 pb-3 md:pr-3 md:pb-0">
          <b>PIO-CAN-BUN</b><br/>
          Filterüberwachung zur kontinuierlichen Überwachung von Druck und Temperatur sowie zur Steuerung von Brenner-Regenerationen.
          <br/><br/>
          <b>PIO-CAN-REG-M/S</b><br/>
          Filterüberwachung zur kontinuierlichen Überwachung von Druck und Temperatur sowie zur Steuerung der elektrischen Regeneration mit bis zu zwei Regelkreisen.
        </div>
        <div className="flex flex-1 justify-center">
          <div><img className="md:max-w-sm" src={imgB5} alt="RST PIO-CAN 1"/></div>
        </div>
      </div>
    </>
  )
}
