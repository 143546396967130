import React from 'react'
import imgDpf1 from 'src/resources/img/DPF-Deckel.jpg'
import imgDpf2 from 'src/resources/img/Schalldaempfer-2.jpg'
import imgDpf3 from 'src/resources/img/CM_FM.jpg'
import imgDpf4 from 'src/resources/img/DPF-Dichtung.jpg'
import imgDpf5 from 'src/resources/img/DPF-Spannring.jpg'
import imgDpf6 from 'src/resources/img/DPF-Klemmring.jpg'
import imgDpf7 from 'src/resources/img/DPF-Bandschellen.jpg'
import imgDpf8 from 'src/resources/img/DPF-Schwingungsdaempfer.jpg'
import imgDpf9 from 'src/resources/img/Filterbag.jpg'
import imgDpf10 from 'src/resources/img/Brenner-komplett.jpg'
import imgDpf11 from 'src/resources/img/Brennerkerze.jpg'
import imgDpf12 from 'src/resources/img/Spanring-Brenner.jpg'

export const DpfErsatzteileJmSysteme = () => {
  return (
    <>
      <h2>DPF-Ersatzteile und Zubehör für JM-DPF Systeme</h2>

      <div className="flex flex-col md:flex-row">
        <div className="flex-none ersatzteile-list">
          <ul className="list">
            <li>Ein- und Austrittsdeckel, konisch, zylindrisch oder kundenspezifisch</li>
            <li>Katalysatormodule</li>
            <li>Filtermodule</li>
            <li>Reparaturen</li>
            <li>Dichtungen</li>
            <li>Spannringe</li>
            <li>Klemmringe</li>
            <li>Filterbags</li>
            <li>Universal-Schalldämpfer</li>
            <li>Schwingungsdämpfer</li>
            <li>Bandschellen</li>
            <li>Brenner und Zubehör</li>
            <li style={{marginTop: '1em'}}>Sonstiges auf Anfrage</li>
          </ul>
          <br/>
        </div>
        <div className="flex flex-col xl:pl-20">
          <div className="flex flex-row md:max-w-2xl">
            <div className="flex items-center px-10">
              <div><img src={imgDpf1} alt="Ein- und Austrittsdeckel, konisch, zylindrisch oder kundenspezifisch"/></div>
            </div>
            <div className="flex items-center px-10">
              <div><img src={imgDpf2} alt="Katalysatormodule"/></div>
            </div>
            <div className="flex items-center px-10">
              <div><img src={imgDpf3} alt="Filtermodule"/></div>
            </div>
          </div>
          <div className="flex flex-row md:max-w-2xl">
            <div className="flex items-center px-10">
              <div><img src={imgDpf4} alt="Reparaturen"/></div>
            </div>
            <div className="flex items-center px-10">
              <div><img src={imgDpf5} alt="Dichtungen"/></div>
            </div>
            <div className="flex items-center px-10">
              <div><img src={imgDpf6} alt="Spannringe"/></div>
            </div>
          </div>
          <div className="flex flex-row md:max-w-2xl">
            <div className="flex items-center px-10">
              <div><img src={imgDpf7} alt="Klemmringe"/></div>
            </div>
            <div className="flex items-center px-10">
              <div><img src={imgDpf8} alt="Filterbags"/></div>
            </div>
            <div className="flex items-center px-10">
              <div><img src={imgDpf9} alt="Universal-Schalldämpfer"/></div>
            </div>
          </div>
          <div className="flex flex-row md:max-w-2xl">
            <div className="flex items-center px-10">
              <div><img src={imgDpf10} alt="Schwingungsdämpfer"/></div>
            </div>
            <div className="flex items-center px-10">
              <div><img src={imgDpf11} alt="Bandschellen"/></div>
            </div>
            <div className="flex items-center px-10">
              <div><img src={imgDpf12} alt="Brenner und Zubehör"/></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}