import React from 'react'
import imgDPR3 from 'src/resources/img/Bobcat-E10-w.jpg'
import imgDpf1 from 'src/resources/img/Particulator2.jpg'
import imgDPR1 from 'src/resources/img/Kubota-1.jpg'
import imgDPR2 from 'src/resources/img/Particulator.jpg'

export const DerParticulator = () => {
  return (
    <>
      <h2>NEU: Der PARTICULATOR</h2>

      <p>
        Ein Partikelfiltersystem mit Regenerationsmöglichkeit zum einfachen Anbau an das Abgassystem.<br/>
        Ideal für Kleinmaschinen von &gt;1kW bis 30kW Motorleistung.<br/>
        Perfekt für Mietgeräte zum schnellen Umrüsten.<br/>
        Wartungsarm durch Regeneration des Filters.<br/>
        Umweltfreundlich mit &gt;99% Partikelabscheidung.<br/>
        <br/>
        Wir suchen für das Vertriebsgebiet Deutschland und Österreich Händler und Vertriebspartner.<br/>
        Bei Interesse senden Sie uns gerne eine Email.
      </p>
      <div>
        <div className="particulator-images">
          <img src={imgDPR1} alt=""/>
          <img src={imgDpf1} alt=""/>
          <img src={imgDPR2} alt=""/>
          <img src={imgDPR3} alt=""/>
        </div>
      </div>

      <br/>
    </>
  )
}
