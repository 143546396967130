import React from 'react'
import imgB14 from 'src/resources/img/RST-PIO-CAN-Monitoring-System2.png'

export const MonitoringSysteme = () => {
  return (
    <>
      <h1>Monitoring-Systeme</h1>

      <div className="flex flex-col md:flex-row justify-between pb-10">
        <div className="flex-1 pb-3 md:pr-3 md:pb-0">
          <p>
            Anzeigen, Überwachung, Alarmierung, Protokollierung und Datalogging von Druck, Temperatur, Füllstand, Feuchte und vielen anderen physikalischen Größen durch vier analoge Eingänge
            mit 0-10 V oder 0-20 mA sowie freie Skalierung der Messbereiche für die jeweiligen kundenseitigen Sensoren.
            <br/>
            Optional können weitere vier analoge Eingänge für den Datalogger konfiguriert werden.
            <br/><br/>
            PIO-System ist ein universelles, kompaktes Monitoring-System für viele Anwendungen im industriellen Bereich.
          </p>
        </div>
        <div className="flex flex-1 justify-center">
          <div><img className="md:max-w-sm" src={imgB14} alt="RST PIO-CAN Monitoring-System"/></div>
        </div>
      </div>
    </>
  )
}
