import React from 'react'
import imgPio20001 from 'src/resources/img/PIO-CAN-2000-M.jpg'
import imgPio20002 from 'src/resources/img/DS-PIO2000.jpg'
import imgPio20003 from 'src/resources/img/Pt100-2.jpg'
import imgPio20004 from 'src/resources/img/PIO2000-INT.jpg'
import imgPio20005 from 'src/resources/img/Y-Verteiler-RST.jpg'
import imgPio20006 from 'src/resources/img/FJPG-B85.jpg'
import imgPio20007 from 'src/resources/img/Anschlussschlauc-PIO2000-90.jpg'
import imgPio20008 from 'src/resources/img/Dichtung-PIO.jpg'
import imgPlatzhalter from 'src/resources/img/platzhalter.png'

export const ErsatzteilePio2000 = () => {
  return (
    <>
      <h2>Ersatzteile PIO-2000</h2>

      <div className="flex flex-col md:flex-row content-between">
        <div className="flex-none ersatzteile-list">
          <ul className="list">
            <li>NEU! Ersatz-Filter-Monitor für PIO2000</li>
            <li>Drucksensor PIO2000</li>
            <li>DS-Anschlusskabel</li>
            <li>Y-Verteiler</li>
            <li>Temperaturfühler PIO2000</li>
            <li>Anschlussschlauch für Drucksensor</li>
            <li>Dichtung</li>
            <li>Interfacekabel</li>
          </ul>
          <br/>
        </div>
        <div className="flex flex-col xl:pl-20">
          <div className="flex flex-row md:max-w-2xl">
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgPio20001} alt="Ersatz-Filter-Monitor für PIO2000"/></div>
            </div>
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgPio20002} alt="Drucksensor PIO2000"/></div>
            </div>
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgPio20003} alt="DS-Anschlusskabel"/></div>
            </div>
          </div>
          <div className="flex flex-row md:max-w-2xl">
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgPio20004} alt="Y-Verteiler"/></div>
            </div>
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgPio20005} alt="Temperaturfühler PIO2000"/></div>
            </div>
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgPio20006} alt="Anschlussschlauch für Drucksensor"/></div>
            </div>
          </div>
          <div className="flex flex-row md:max-w-2xl">
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgPio20007} alt="Dichtung"/></div>
            </div>
            <div className="flex items-center px-10 sm:pb-20 lg:pb-5">
              <div><img src={imgPio20008} alt="Interfacekabel"/></div>
            </div>
            <div className="flex items-center px-10">
              <div><img src={imgPlatzhalter} alt=""/></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
