import React from 'react'
import { DerParticulator } from 'src/components/leistungen/DerParticulator'
import { DieselPartikelfilterRussfilter } from 'src/components/leistungen/DieselPartikelfilterRussfilter'
import { DpfErsatzteileJmSysteme } from 'src/components/leistungen/DpfErsatzteileJmSysteme'
import { ErsatzteilePio2000 } from 'src/components/leistungen/ErsatzteilePio2000'
import { ErsatzteilePioCan } from 'src/components/leistungen/ErsatzteilePioCan'
import { Section } from 'src/components/leistungen/Section'

export const Ersatzteile = () => {
  return (
    <>
      <h1>Ersatzteile</h1>

      <p>Wir liefern Ersatzteile für unsere Filterüberwachungen PIO-CAN sowie für die vorangegangenen Systeme PIO2000.</p>

      <br/>

      <Section id="ersatzteile-pio-can"><ErsatzteilePioCan/></Section>
      <Section id="ersatzteile-pio2000"><ErsatzteilePio2000/></Section>
      <Section id="diesel-partikelfilter-russfilter"><DieselPartikelfilterRussfilter/></Section>
      <Section id="der-particulator"><DerParticulator/></Section>
      <Section id="dpf-ersatzteile-jm-systeme"><DpfErsatzteileJmSysteme/></Section>
    </>
  )
}
