import React from 'react'
import imgB2 from 'src/resources/img/RST-CD.jpg'
import imgB5 from 'src/resources/img/RST-PIO-CAN-3.jpg'

export const DPFFilterueberwachungMitSensorik = () => {
  return (
    <>
      <h1>DPF-Filterüberwachung mit Sensorik</h1>

      <p>
        Die Filterüberwachung PIO-CAN ist für nahezu alle Diesel-Partikelfilter einsetzbar. Druck und Temperatur werden mit dem integriertem Datalogger aufgezeichnet. Alle Betriebs- und Störmeldungen werden in einem Alarmspeicher abgelegt.
        <br/>
        In der Standardversion wird der Abgasgegendruck und die Abgastemperatur aufgezeichnet. Optional kann die Drehzahl, oder andere Analogsignale mit aufgezeichnet werden.
        <br/>
        Meldungen werden im Graphikdisplay als Klartext angezeigt – keine Fehlercodes! Direkt an der Filterüberwachungen kann zwischen 5 voreingestellten Sprachen ausgewählt werden.
        Weitere Varianten steuern die elektrische Regeneration mit bis zu 2 unabhängigen Regelungen sowie die Diesel-Brennersteuerung.
        <br/>
        Mit der optional erhältlichen Diagnosesoftware PIO-CAN-4.0 können Grenzwerteinstellung verändert und Daten zu Servicezwecken ausgewertet werden.
      </p>

      <br/>

      <div className="flex flex-col md:flex-row justify-between pb-10">
        <div className="flex-1 pb-3 md:pr-3 md:pb-0">
          <b>PIO-CAN-USB</b><br/>
          Standard Filterüberwachung zur kontinuierlichen Überwachung von Druck und Temperatur.
          <br/><br/>
          <b>PIO-CAN-DUAL</b><br/>
          Filterüberwachung zur kontinuierlichen Überwachung von 2 x Druck und 2 x Temperatur, z. B. für den Einsatz an V-Motoren.
          <br/><br/>
          <b>PIO-CAN-NOx</b><br/>
          Filterüberwachung zusätzlich mit CAN-BUS (SAE-J1939) zur kontinuierlichen Überwachung von Stickoxidwerten mit NOx-Sensoren, zum Beispiel für den Einsatz in SCR-Anlagen (Stag V).
          <br/><br/>
          <b>PIO-CAN-Sonder</b><br/>
          Filterüberwachung mit kundenspezifischer Konfiguration.
        </div>
        <div className="flex flex-1 justify-center">
          <div><img className="md:max-w-sm" src={imgB5} alt="RST PIO-CAN 1"/></div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex-1 pb-3 md:pr-3 md:pb-0">
          <b>PIO-CAN-Software V4.0</b><br/>
          Parametrier- und Diagnose-Software.
        </div>
        <div className="flex flex-1 justify-center">
          <div><img className="md:max-w-sm" src={imgB2} alt="RST CD"/></div>
        </div>
      </div>
    </>
  )
}
